import React from 'react'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { LayoutWrapper } from '../components/layout/layoutWrapper'

const YXJjb25zaXN3YXNoZXJl: React.FC = () => {
  const currentLocale: AppLocale = GenerateLocales.de

  return (
    <>
      <LayoutWrapper
        currentLocale={currentLocale}
        otherLocales={[{ locale: GenerateLocales.en, slug: '/en/YXJjb25zaXN3YXNoZXJl' }]}
        hideFooterButtons={true}
        hideFooterLinks={false}
      >
        <br />
        <br />
      </LayoutWrapper>
    </>
  )
}

export default YXJjb25zaXN3YXNoZXJl
